export default function (): Ref<LoginUser> {
  const loginAccount = useState<LoginUser>('loginAccount', () => {
    return {
      userId: 0,
      accountId: 0,
      userName: '',
      iat: 0,
      exp: 0,
      accessToken: '',
      level: 0,
      role: ''
    }
  })

  const session = useCookie('luser').value
  if (!loginAccount.value.userId && session) {
    loginAccount.value = JSON.parse(JSON.stringify(session))
  }

  return loginAccount
}
